<template>
  <b-row
    v-if="ready"
  >
    <b-col lg="12">

      <b-form-group
        :label="`Images / Vidéos `"
        label-for="last-name"
      >
        <b-form-file
          multiple
          placeholder="Veuillez choisir des fichiers à mettre dans ce dossier"
          size="lg"
          v-model="file"
          accept="video/*, image/*, .doc,.docx, .pdf"
        />
      </b-form-group>
    </b-col>
    <b-col
      v-for="drive in drives"
      :key="drive.id"
      sm="12"
      md="3"
      lg="3"
    >
      <b-card class="text-center" style="border: 0.5px solid grey; -moz-box-shadow: 3px 3px 5px 6px #ccc; -webkit-box-shadow: 3px 3px 5px 6px #ccc; box-shadow: 3px 3px 5px 6px #ccc;">
        <h5 class="text-center">#{{ drive.id }}. {{ drive.title }}</h5>
        <hr/>
        <video
          style="width:100%;"
          v-if="drive.file != null && !drive.file.includes('vidyard') && drive.file !== '' && drive.file.includes('.mp4') && !drive.file.includes('.m4v') && !drive.file.includes('.mov') && !drive.file.includes('.docx') && !drive.file.includes('.xls') && !drive.file.includes('.xlsx')"
          controls>
          <source
            :src="`https://app2.oscar-black.com/uploads/visuels/${drive.file}`"
            class="rounded mb-50"
          >
        </video>
        <pdf v-else-if="drive.file != null && drive.file !== '' && drive.file.includes('.pdf')"
        :src="`https://app2.oscar-black.com/uploads/visuels/${drive.file}`"></pdf>
          <!-- post video -->
        <div v-else-if="drive.file != null && drive.file !== '' && !drive.file.includes('.pdf') && !drive.file.includes('.m4v') && !drive.file.includes('.mov') && !drive.file.includes('.docx') && !drive.file.includes('.xls') && !drive.file.includes('.xlsx')"
        v-viewer
        >
          <img style="width:100%;" :src="`https://app2.oscar-black.com/uploads/visuels/${drive.file}`"  />
        </div>
        <hr/>
        <b-card-text
          class="mt-1"
        >
          <small class="text-muted w-100">Publié le {{ (new Date(drive.date*1000)).getDate() }}/{{ (new Date(drive.date*1000)).getMonth()+1 }}/{{ (new Date(drive.date*1000)).getFullYear() }} <br/></small>
        </b-card-text>
        <b-row>
          <b-col md="6">
            <a :href="`https://app2.oscar-black.com/uploads/visuels/${drive.file}`" target="_blank">
              <b-button
                variant="primary"
                class="btn-icon mb-1"
                style="width:80%"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
            </a>
          </b-col>
          <b-col md="6">
            <b-button
              variant="danger"
              class="btn-icon mb-1"
              @click="confirmDelete(drive.id)"
              style="width:80%"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import router from '@/router'
import axios from '@axios'
import {
  BCard, BCardText, BRow, BCol, BButton, BImg, BSidebar, VBToggle, BCarousel, BCarouselSlide, BEmbed, BAvatar, BAvatarGroup, BFormGroup, BFormInput, BFormFile,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Ripple from 'vue-ripple-directive'


import 'viewerjs/dist/viewer.css'
import pdf from 'vue-pdf'



export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BImg,
    BSidebar,
    /* eslint-disable vue/no-unused-components */
    VBToggle,
    BCarousel,
    BCarouselSlide,
    BEmbed,
    BAvatar,
    BAvatarGroup,
    BFormGroup,
    BFormInput,
    BFormFile,
    ToastificationContent,
    pdf,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      drives: [],
      ready: false,
      file: null,
    }
  },
  methods: {
    confirmDelete(id) {
      this.$swal({
        title: 'Êtes-vous sûr ?',
        text: 'Il sera impossible de revenir en arrière...',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, supprimer ce fichier',
        cancelButtonText: 'STOP',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const req = {
            _id: id,
            iddossier: router.currentRoute.params.id,
          }
          axios
            .delete('https://app2.oscar-black.com/api/drive', { data: req })
            .then(response => {
              this.drives = response.data
              this.$swal({
                icon: 'success',
                title: 'Supprimé !',
                text: 'Votre fichier a bien été supprimé.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              this.$swal({
                icon: 'error',
                title: 'Oops, pas supprimé !',
                text: error.response.data,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Annulé',
            text: 'Votre fichier est en sécurité :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },

  },
  created() {
    axios
      .get('https://app2.oscar-black.com/api/drive/'+router.currentRoute.params.id)
      .then(response => {
        this.drives = response.data
        setTimeout(() => { this.ready = true }, 500)
      })
      .catch(() => {})
  },
  watch: {
    async file(newvalue) {
      if (newvalue !== null) {

        for(var i = 0; i < newvalue.length; i++) {
          if (newvalue[i].type.includes('image')) {
            let reader = new FileReader()
            reader.readAsDataURL(newvalue[i])
            var vm = this
            let name = newvalue[i].name
            let fil = newvalue[i]

            reader.onload = async e => {

              var image = new Image()
              image.src = e.target.result

              image.onload = async () => {

                var { height, width } = this
                if (height === width || width / height === 0.5625) {
                  vm.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Le visuel est au bon format :)',
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  var formData = new FormData()

                  formData.append('file', fil)
                  formData.append('title', name)
                  formData.append('id', router.currentRoute.params.id)

                  axios
                    .post('https://app2.oscar-black.com/api/drive', formData, {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                    })
                    .then(response => {
                      vm.file = null;
                      vm.drives = response.data;
                      vm.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Fichier ajouté !',
                          icon: 'CheckIcon',
                          variant: 'success',
                        },
                      })
                    })
                    .catch(() => {
                      vm.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Erreur',
                          icon: 'CrossIcon',
                          variant: 'danger',
                        },
                      })
                    })
                } else {
                  vm.file = null
                  vm.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Erreur, le visuel doit être au format carré ou au format 9:16 (1080x1920)',
                      icon: 'CrossIcon',
                      variant: 'danger',
                    },
                  })
                }
                return true
              }
            }
          }
          else {
            var formData = new FormData()

            formData.append('file', newvalue[i])
            formData.append('title', newvalue[i].name)
            formData.append('id', router.currentRoute.params.id)

            axios
              .post('https://app2.oscar-black.com/api/drive', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
              .then(response => {
                this.drives = response.data;
                this.file = null;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Fichier ajouté !',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'CrossIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
  .custom-file-input:lang(fr) ~ .custom-file-label::after {
    content: 'Parcourir';
  }
</style>
